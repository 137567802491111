import React from 'react';

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FieldTextAreaElem } from '../../../common/field-text-area';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { getPersonList } from '../../../data/person/action';
import { convertPersonListToSelect } from '../../../data/person/convert';
import { SelectDynamicContainer } from '../../select-dynamic';
import styled from 'styled-components';
import { Option } from '../../../data/message/frame/select/option';
import { EVENT_TYPE } from '../../../data/event/constant';
import { convertEventMessageToSelect } from '../../../data/event/convert';
import { getEventList } from '../../event-list';

export const MessageForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  sessionId: string;
  setFieldValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  sessionId,
}) => {
  return (
    <GridElem spacing={5}>
      <DoubleContainerElem>
        <SelectDynamicContainer
          formik={formik}
          name={FORM_VALUE_ENUM.PERSON}
          value={getFieldValue(FORM_VALUE_ENUM.PERSON)}
          action={getPersonList}
          convert={convertPersonListToSelect}
          title="EVENT.FORM.PERSON"
          errorMessage={getFieldError(FORM_VALUE_ENUM.PERSON)}
          error={isFieldError(FORM_VALUE_ENUM.PERSON)}
          param={sessionId}
        />

        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          title="EVENT.FORM.TIME"
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
          formik={formik}
        />
      </DoubleContainerElem>
      <DoubleContainerElem>
        <Select
          formik={formik}
          name={FORM_VALUE_ENUM.MESSAGE_REPLY}
          value={getFieldValue(FORM_VALUE_ENUM.MESSAGE_REPLY)}
          action={getEventList}
          convert={convertEventMessageToSelect}
          param={{
            sessionId,
            type: [{ value: EVENT_TYPE.MESSAGE }],
            startTime: getFieldValue(FORM_VALUE_ENUM.TIME),
          }}
          customComponents={{ Option }}
          title="EVENT.FORM.MESSAGE_REPLY"
          isClearable={true}
        />
      </DoubleContainerElem>
      <FieldTextAreaElem
        name={FORM_VALUE_ENUM.MESSAGE}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="EVENT.FORM.MESSAGE"
        value={getFieldValue(FORM_VALUE_ENUM.MESSAGE)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.MESSAGE)}
        error={isFieldError(FORM_VALUE_ENUM.MESSAGE)}
      />
    </GridElem>
  );
};

const Select = styled(SelectDynamicContainer)`
  div > .Select__option {
    height: 48px;
    display: flex;
  }
`;
