import styled from 'styled-components';
import { GridElem } from '../../../common/grid';
import { COLOR_ENUM } from '../../../theme/color';
import { ProgressTitleElem } from './progressTitle';

export const ProgressElem: React.FC<{}> = ({}) => {
  return (
    <GridElem
      style={{
        maxWidth: '689px',
        margin: 'auto',
      }}
      spacing={2}
    >
      <ProgressTitleElem center />
      <ProgressContainer>
        <ProgressContent />
      </ProgressContainer>
    </GridElem>
  );
};

const ProgressContainer = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
`;

const ProgressContent = styled.div`
  width: 90%;
  height: 100%;
  border-radius: 8px 0 0 8px;
  background: ${({ theme }) => theme[COLOR_ENUM.BUTTON_PRIMARY]};
`;
