import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { FORM_VALUE_ENUM } from '../person-item-create/constant';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import closeIcon from '../../asset/svg/common/close.svg';
import { ToggleElem } from '../../common/toggle';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { FileItemUploadContainer } from '../file-item-upload';
import { SelectElem } from '../../common/select';
import { PERSON_ROLE_OPTION_LIST } from '../../data/person/constant';
import { FieldTimeInputElem } from '../../common/field-time-input';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
  onSuccessUpload: Function;
  photo: string;
  setFieldValue: Function;
  setValue: Function;
  onResetPhoto: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  close,
  onSuccessUpload,
  photo,
  setFieldValue,
  setValue,
  onResetPhoto,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <Container spacing={4}>
        <TitleContainer>
          <TextElem tid="PERSON.UPDATE.TITLE" type="semi-bold" size="main" />
          <Icon src={closeIcon} onClick={() => close()} />
        </TitleContainer>
        <FormContainer spacing={5}>
          <GridElem spacing={3}>
            <FieldTextElem
              name={FORM_VALUE_ENUM.NAME}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="PERSON.FORM.NAME"
              value={getFieldValue(FORM_VALUE_ENUM.NAME)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
              error={isFieldError(FORM_VALUE_ENUM.NAME)}
            />
            <DoubleContainerElem>
              <SelectElem
                name={FORM_VALUE_ENUM.ROLE}
                onChange={setFieldValue}
                options={PERSON_ROLE_OPTION_LIST}
                title="USER.CREATE.FORM.ROLE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.ROLE)}
                error={isFieldError(FORM_VALUE_ENUM.ROLE)}
                value={setValue(PERSON_ROLE_OPTION_LIST, FORM_VALUE_ENUM.ROLE)}
              />
              <FieldTextElem
                name={FORM_VALUE_ENUM.PHONE}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="phone"
                title="PERSON.FORM.PHONE"
                value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
                error={isFieldError(FORM_VALUE_ENUM.PHONE)}
              />
            </DoubleContainerElem>

            <DoubleContainerElem>
              <FieldTextElem
                name={FORM_VALUE_ENUM.COUNTRY}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="PERSON.FORM.COUNTRY"
                value={getFieldValue(FORM_VALUE_ENUM.COUNTRY)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.COUNTRY)}
                error={isFieldError(FORM_VALUE_ENUM.COUNTRY)}
              />
              <FieldTextElem
                name={FORM_VALUE_ENUM.CITY}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="PERSON.FORM.CITY"
                value={getFieldValue(FORM_VALUE_ENUM.CITY)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
                error={isFieldError(FORM_VALUE_ENUM.CITY)}
              />
            </DoubleContainerElem>
            <FieldTimeInputElem
              name={FORM_VALUE_ENUM.RESERVATION_TIME}
              onChange={formik.handleChange}
              title="PERSON.FORM.RESERVATION_TIME"
              value={getFieldValue(FORM_VALUE_ENUM.RESERVATION_TIME)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.RESERVATION_TIME)}
              error={isFieldError(FORM_VALUE_ENUM.RESERVATION_TIME)}
              formik={formik}
            />
          </GridElem>
          <GridElem spacing={2}>
            <TextElem tid="PERSON.FORM.PHOTO" color="textPrimary" />
            <FileItemUploadContainer
              onSuccess={onSuccessUpload}
              name={FORM_VALUE_ENUM.FILE}
              type="image"
              maxSize={3145728}
              maxSizeText="3 MB"
              defaultValue={photo}
              description={false}
              onReset={onResetPhoto}
            />
          </GridElem>
        </FormContainer>

        <DoubleContainerElem>
          <ButtonElem
            type="submit"
            tid="PERSON.UPDATE.BUTTON.UPDATE"
            fill="solid"
            disabled={isSubmitDisabled()}
            color="success"
          />
        </DoubleContainerElem>

        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
      </Container>
    </FormElem>
  );
};

const Container = styled(GridElem)`
  @media screen and (width>900px) {
    gap: ${Spacing(5)};
  }
`;

const FormContainer = styled(GridElem)`
  grid-template-columns: auto 125px;
  .file-upload-wrapper {
    height: 125px !important;
  }
`;

const FieldContainer = styled(GridElem)`
  @media screen and (width>600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (width>900px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Grid = styled(GridElem)`
  @media screen and (width>600px) {
    grid-template-columns: auto max-content;
    gap: ${Spacing(3)};
  }
  gap: ${Spacing(4)};
`;

const Toggle = styled(ToggleElem)`
  height: 20px !important;
  width: 33px !important;
  --handle-width: 15px;
  --handle-height: 15px;
  --handle-spacing: 1.8px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleContainer = styled.div`
  @media screen and (width>600px) {
    margin-top: 26px;
  }
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: 10px;
  padding: 0 16px;

  height: 46px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  div > div {
    height: 19px;
  }
`;

const Icon = styled.img`
  cursor: pointer;
`;
