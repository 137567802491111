import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { ButtonElem } from '../../../common/button';
import { createReservation } from '../action';
import { useMutation } from 'react-query';
import { decryptSecretKey } from '../../../lib/util/crypto';
import { GridElem } from '../../../common/grid';
import styled from 'styled-components';
import { LoaderElem } from '../../../common/loader';
import { TextElem } from '../../../common/text';

const CheckoutForm: React.FC<{ reservationId: string }> = ({
  reservationId,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSuccess = async (d: any) => {
    const secretKey = decryptSecretKey(d);

    if (stripe && elements) {
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: `${secretKey}`,

        confirmParams: {
          return_url: 'https://example.com/order/123/complete',
        },
        redirect: 'if_required',
      });

      if (error) {
        setErrorMessage(error.message || 'An error occurred');
      } else {
      }
    }
  };

  const action = useMutation(() => createReservation(reservationId), {
    onSuccess,
  });

  const onClick = async (event: any) => {
    event.preventDefault();

    if (!elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message || 'An error occurred');
      return;
    }

    action.mutate();
  };

  return (
    <Form>
      {action.isLoading && <LoaderElem />}
      <GridElem spacing={5}>
        <PaymentElement />
        <ButtonElem
          type="button"
          disabled={
            !stripe ||
            !elements ||
            action.isLoading ||
            action.isError ||
            action.isSuccess
          }
          tid="Оплатити"
          onClick={onClick}
        />
        {(errorMessage || action.isError) && (
          <TextElem color="error">{errorMessage || action.error}</TextElem>
        )}
      </GridElem>
    </Form>
  );
};

const Form = styled.form`
  padding: 24px;
  /* background: #fcfaff; */
  border-radius: 16px;
  @media screen and (width<600px) {
    padding: 12px;
  }
`;

export default CheckoutForm;
