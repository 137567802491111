import { FILE_ITEM_DATA_RAW } from '../file/constant';
import { SESSION_ITEM_DATA_RAW } from '../session/constant';
import { WEBINAR_ITEM_DATA_RAW } from '../webinar/constant';
import { i18n } from '../../lib/lang';

export enum BAN_TYPE {
  NONE = 'NONE',
  PERMANENT = 'PERMANENT',
}

export enum PERSON_ROLE {
  USER = 'USER',
  MODER = 'MODER',
  SPEAKER = 'SPEAKER',
}

export interface PERSON_ITEM_DATA_RAW {
  id: string;
  name: string;
  phone: string;
  photo: FILE_ITEM_DATA_RAW;
  webinar: WEBINAR_ITEM_DATA_RAW;
  session: SESSION_ITEM_DATA_RAW;
  city: string;
  country: string;
  role: PERSON_ROLE;
  photoUrl: string;
  reservationTime: string;
}

export const PERSON_ROLE_OPTION_LIST = [
  {
    value: PERSON_ROLE.USER,
    label: `${i18n.t('USER.ROLE.USER')}`,
  },
  {
    value: PERSON_ROLE.MODER,
    label: `${i18n.t('USER.ROLE.MODER')}`,
  },
  {
    value: PERSON_ROLE.SPEAKER,
    label: `${i18n.t('USER.ROLE.SPEAKER')}`,
  },
];

export interface PERSON_ITEM_LIST_DATA_RAW {
  list: PERSON_ITEM_DATA_RAW[];
}

export interface PERSON_ITEM_DATA extends PERSON_ITEM_DATA_RAW {
  status?: string;
}

export interface PERSON_ITEM_LIST_DATA {
  list: PERSON_ITEM_DATA[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: (sessionId: string, term?: string) =>
      `person/list/${sessionId}?search=${term}`,
  },
};
