import React from 'react';
import { Pie, PieChart, Cell, Tooltip } from 'recharts';
import { TextElem } from '../text';
import styled from 'styled-components';
import { ContentContainerElem } from '../content-container';
import { THEME_ENUM } from '../../data/theme/constant';
import {
  COLOR_ENUM,
  DARK_COLOR_DATA,
  LIGHT_COLOR_DATA,
} from '../../theme/color';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { GridElem } from '../grid';
import { FlexElem } from '../flex';
import { EllipsisContainerElem } from '../ellipsis-container';
import { ReactComponent as EmptyIcon } from '../../asset/svg/analytic/empty.svg';

const COLORS = [
  '#903CFA',
  '#2E9973',
  '#D127B6',
  '#45ACE5',
  '#E6591F',
  '#4F5CA8',
  '#26724E',
  '#D13D58',
  '#1F5B99',
  '#B33A3A',
  '#673DA8',
  '#3CB4C6',
  '#C85079',
  '#5CB240',
  '#EF4C43',
];

const GRADIENT_COLORS = [
  '#1B1924, linear-gradient(180deg, rgba(144, 60, 250, 0.5) 0%, rgba(144, 60, 250, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(46, 153, 115, 0.5) 0%, rgba(46, 153, 115, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(209, 39, 182, 0.5) 0%, rgba(209, 39, 182, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(69, 172, 229, 0.5) 0%, rgba(69, 172, 229, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(230, 89, 31, 0.5) 0%, rgba(230, 89, 31, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(79, 92, 168, 0.5) 0%, rgba(79, 92, 168, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(38, 114, 78, 0.5) 0%, rgba(38, 114, 78, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(209, 61, 88, 0.5) 0%, rgba(209, 61, 88, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(31, 91, 153, 0.5) 0%, rgba(31, 91, 153, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(179, 58, 58, 0.5) 0%, rgba(179, 58, 58, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(103, 61, 168, 0.5) 0%, rgba(103, 61, 168, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(60, 180, 198, 0.5) 0%, rgba(60, 180, 198, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(200, 80, 121, 0.5) 0%, rgba(200, 80, 121, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(92, 178, 64, 0.5) 0%, rgba(92, 178, 64, 0.5) 100%)',
  '#1B1924, linear-gradient(180deg, rgba(239, 76, 67, 0.5) 0%, rgba(239, 76, 67, 0.5) 100%)',
];

// Функция для преобразования CSS-градиента в SVG-градиент
const getSvgGradient = (index: number) => {
  return (
    <linearGradient
      id={`gradient-${index}`}
      x1="0"
      y1="0"
      x2="0"
      y2="1"
      // gradientUnits="userSpaceOnUse"
    >
      <stop stopColor={COLORS[index]} stop-opacity="1" />
      <stop offset="1" stopColor={COLORS[index]} stop-opacity="0.2" />
    </linearGradient>
  );
};

export const Elem: React.FC<{
  data: { name: string; value: number }[];
  title?: string;
  subTitle?: string;
}> = ({ data, title, subTitle }) => {
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={11}
        fontWeight={600}
      >
        {`${percent}%`}
      </text>
    );
  };

  const totalValue = data.reduce((acc, cur) => acc + cur.value, 0);

  const modifiedDataInfo: Array<{
    color: string;
    percent: string;
    name: string;
    value: number;
  }> = data.map((entry, index) => ({
    ...entry,
    color: GRADIENT_COLORS[index % GRADIENT_COLORS.length],
    percent: ((entry.value / totalValue) * 100).toFixed(0),
  }));

  const modifiedData: Array<{
    color: string;
    percent: string;
    name: string;
    value: number;
  }> = modifiedDataInfo
    .filter((entry) => entry.value !== 0)
    .map((entry, index) => ({
      ...entry,
    }));

  const { theme: themeType } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  const theme =
    themeType.type === THEME_ENUM.LIGHT ? LIGHT_COLOR_DATA : DARK_COLOR_DATA;

  return (
    <>
      {modifiedData.length ? (
        <ContainerEmpty>
          {title && (
            <Header>
              <TextElem size="label" type="semi-bold" tid={title}></TextElem>
              <TextElem
                size="small"
                tid={subTitle}
                color="textThird"
              ></TextElem>
            </Header>
          )}

          <Container>
            <PieChart width={120} height={120} style={{ opacity: '0.8' }}>
              {/* Добавляем SVG-градиенты */}
              <defs>{COLORS.map((item, index) => getSvgGradient(index))}</defs>

              <Tooltip content={<></>} />

              <Pie
                data={modifiedData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={60}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {modifiedData.map((entry: any, index) => (
                  <Cell
                    stroke={`${theme[COLOR_ENUM.BACKGROUND_PRIMARY]}`}
                    strokeWidth={modifiedData.length > 1 ? 2 : 0}
                    key={`cell-${index}`}
                    fill={`url(#gradient-${index})`} // Используем SVG-градиент
                  />
                ))}
              </Pie>
            </PieChart>
            <FlexElem style={{ height: '100%' }}>
              <GridElem
                spacing={2}
                style={{
                  alignItems: 'center',
                  height: 'min-content',
                  gridAutoColumns: '12px auto min-content min-content',
                }}
              >
                {modifiedDataInfo.map((item: any, index) => (
                  <ItemContainer spacing={1}>
                    <Dot style={{ background: `${item.color}` }} />
                    <EllipsisContainerElem>
                      <TextElem
                        type="medium"
                        color="textSecondary"
                        oneLine
                        tid={item.name}
                        title={item.name}
                      />
                    </EllipsisContainerElem>

                    <div style={{ textAlign: 'end', marginLeft: '8px' }}>
                      <TextElem type="semi-bold" color="textSecondary">
                        {item.value}
                      </TextElem>
                    </div>
                    <div>
                      <TextElem
                        type="semi-bold"
                        color="textThird"
                      >{`${item.percent}%`}</TextElem>
                    </div>
                  </ItemContainer>
                ))}
              </GridElem>
            </FlexElem>
          </Container>
        </ContainerEmpty>
      ) : (
        <ContainerEmpty>
          <GridElem spacing={5} style={{ justifyContent: 'center' }}>
            <EmptyIcon style={{ justifySelf: 'center' }} />
            <TextElem tid="Нажаль, поки немає ніяких даних" />
          </GridElem>
        </ContainerEmpty>
      )}
    </>
  );
};

const ContainerEmpty = styled(ContentContainerElem)`
  padding: 16px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  height: 100%;
  align-items: center;
`;

const Container = styled(GridElem)`
  grid-template-columns: 120px auto;
  @media screen and (width<500px) {
    grid-template-columns: 1fr;
  }
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  border-radius: 100%;
`;

const ItemContainer = styled(GridElem)`
  display: grid;
  grid-column: span 4;
  grid-template-columns: subgrid;
  justify-content: center;
  align-items: center;
`;

const Header = styled(FlexElem)`
  justify-content: space-between;
`;
