import React from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
} from 'recharts';
import styled from 'styled-components';
import { ContentContainerElem } from '../content-container';
import { CustomTooltip } from './frame/tooltip';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { THEME_ENUM } from '../../data/theme/constant';
import {
  LIGHT_COLOR_DATA,
  DARK_COLOR_DATA,
  COLOR_ENUM,
} from '../../theme/color';
import { TextElem } from '../text';
import { FlexElem } from '../flex';
import { GridElem } from '../grid';
import { ReactComponent as EmptyIcon } from '../../asset/svg/analytic/empty.svg';

const COLORS = [
  '#903CFA',
  '#2E9973',
  '#D127B6',
  '#45ACE5',
  '#E6591F',
  '#4F5CA8',
  '#26724E',
  '#D13D58',
  '#1F5B99',
  '#B33A3A',
  '#673DA8',
  '#3CB4C6',
  '#C85079',
  '#5CB240',
  '#EF4C43',
];

export const Elem: React.FC<{
  data: { name: string; value: number }[];
  title?: string;
  subTitle?: string;
}> = ({ data, title, subTitle }) => {
  const { theme: themeType } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  const theme =
    themeType.type === THEME_ENUM.LIGHT ? LIGHT_COLOR_DATA : DARK_COLOR_DATA;

  const modifiedData = data.map((entry, index) => {
    if (entry.value != 0) {
      return {
        ...entry,
        color: COLORS[index % COLORS.length],
      };
    }
  });

  return (
    <Container>
      <Header>
        <TextElem size="label" type="semi-bold" tid={title}></TextElem>
        <TextElem size="small" tid={subTitle} color="textThird"></TextElem>
      </Header>
      {modifiedData.length ? (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={modifiedData}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <CartesianGrid
              stroke={theme[COLOR_ENUM.TEXT_THIRD]}
              strokeDasharray="none"
              strokeWidth={1}
              vertical={false}
            />
            <XAxis
              tickSize={0}
              tickMargin={12}
              dataKey="name"
              tick={{
                width: 70,
                fill: `${theme[COLOR_ENUM.TEXT_THIRD]}`,
                fontSize: 12,
              }}
            />
            <YAxis
              tickSize={0}
              tickMargin={12}
              tick={{ fill: `${theme[COLOR_ENUM.TEXT_THIRD]}`, fontSize: 12 }}
              width={40}
            />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <Bar dataKey="value" isAnimationActive={false}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#gradient-${index})`} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <GridElem spacing={5} style={{ justifyContent: 'center' }}>
          <EmptyIcon style={{ justifySelf: 'center' }} />
          <TextElem tid="Нажаль, поки немає ніяких даних" />
        </GridElem>
      )}
    </Container>
  );
};

const Container = styled(ContentContainerElem)`
  padding: 16px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  width: 100%;
  height: 100%;
`;

const Header = styled(FlexElem)`
  justify-content: space-between;
`;
