import React from 'react';

import { MODULE_NAME } from './constant';

import { useQuery } from 'react-query';

import {
  SESSION_ITEM_DATA,
  SESSION_ITEM_DATA_RAW,
} from '../../data/session/constant';
import { convertSession } from '../../data/session/convert';
import { useHistory } from 'react-router';
import { getSession } from './action';
import { SESSION_ITEM_PAGE_PATH_DYNAMIC } from '../../page/session-item-data';

export const Container: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const history = useHistory();
  const onSuccess = (d: any) => {
    history.push(SESSION_ITEM_PAGE_PATH_DYNAMIC(d));
  };
  const preFetch = useQuery(MODULE_NAME, () => getSession(sessionId), {
    onSuccess,
  });

  const getData = (): SESSION_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as SESSION_ITEM_DATA_RAW;

    if (data) {
      return convertSession(data);
    }
  };

  const data = getData();

  return <></>;
};
