import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import closeIcon from '../../asset/svg/common/close.svg';
import { FORM_VALUE_ENUM } from '../project-item-create/constant';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { FileItemUploadContainer } from '../file-item-upload';
import { Spacing } from '../../theme';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
  onSuccessUpload: Function;

  onResetPhoto: Function;
  faviconUrl: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  close,
  onSuccessUpload,

  onResetPhoto,
  faviconUrl,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        <TitleContainer>
          <TextElem tid="PROJECT.UPDATE.TITLE" type="semi-bold" size="main" />
          <Icon src={closeIcon} onClick={() => close()} />
        </TitleContainer>
        <FormContainer spacing={5}>
          <GridElem spacing={3}>
            <FieldTextElem
              name={FORM_VALUE_ENUM.NAME}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="PROJECT.FORM.NAME"
              value={getFieldValue(FORM_VALUE_ENUM.NAME)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
              error={isFieldError(FORM_VALUE_ENUM.NAME)}
            />

            <FieldTextElem
              name={FORM_VALUE_ENUM.SMS_ALPHA_NAME}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="PROJECT.FORM.SMS_ALPHA_NAME"
              value={getFieldValue(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
              error={isFieldError(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
            />
          </GridElem>
          <GridElem spacing={3}>
            <TextElem
              tid="PROJECT.FORM.FAVICON"
              color="textPrimary"
              style={{ height: '14px' }}
            />
            <FileItemUploadContainer
              onSuccess={onSuccessUpload}
              name={FORM_VALUE_ENUM.FAVICON}
              defaultValue={faviconUrl}
              type="image"
              maxSize={3145728}
              maxSizeText="3 MB"
              description={false}
              onReset={onResetPhoto}
            />
          </GridElem>
        </FormContainer>

        <DoubleContainerElem>
          <ButtonStyled
            type="submit"
            tid="PROJECT.UPDATE.BUTTON"
            fill="solid"
            disabled={isSubmitDisabled()}
            color="success"
          />
        </DoubleContainerElem>

        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && <AlertActionElem type="success" tid="Success" />}
      </GridElem>
    </FormElem>
  );
};

const FormContainer = styled(GridElem)`
  grid-template-columns: auto 130px;

  @media screen and (width<700px) {
    grid-template-columns: 1fr;
    gap: ${Spacing(3)};
  }

  .file-upload-wrapper {
    height: 130px !important;
    min-height: 130px !important;
  }
`;

const ButtonStyled = styled(ButtonElem)`
  margin-top: 26px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;
`;
