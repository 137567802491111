import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { FlexElem } from '../../../common/flex';
import { ButtonElem } from '../../../common/button';
import ReservationIcon from '../../../asset/svg/session/reservation.svg';
import { ViewerCounter } from './viewerCounter';
import styled from 'styled-components';
import { ContentContainerElem } from '../../../common/content-container';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import { EVENT_ITEM_DATA_RAW } from '../../../data/event/constant';

export const ReservationOpen: React.FC<{
  modalOpen: Function;
  event: EVENT_ITEM_DATA_RAW;
}> = ({ modalOpen, event }) => {
  return (
    <Container spacing={4}>
      <p>
        <TextElem
          tid="SESSION.VIEWER.RESERVATION.TITLE_1"
          type="semi-bold"
          size="medium"
        />
        <TextElem
          tid="SESSION.VIEWER.RESERVATION.TITLE_2"
          type="semi-bold"
          size="medium"
          color="active"
        />
      </p>
      <ButtonContainer spacing={2}>
        <ButtonStyled
          tid={event?.buttonText}
          onClick={modalOpen}
          iconLeft={ReservationIcon}
          iconSize={20}
          type="button"
        />
        {event?.counterVisible && (
          <ViewerCounter
            placeAvailable={event?.placeAvailable || 100}
            placeReserved={event?.placeReserved || 60}
          />
        )}
      </ButtonContainer>
    </Container>
  );
};

const ButtonStyled = styled(ButtonElem)<{ counterVisible?: boolean }>`
  height: 58px;
  div > span {
    font-size: 20px;
  }
  width: fit-content;
  div > img {
    height: 18px;
    width: 18px;
  }
  div {
    align-items: center;
  }

  /* @media screen and (960px<width<1040px) {
    width: 100%;
  } */
  @media screen and (width<700px) {
    width: 100%;
    height: 48px;
    div > span {
      font-size: 14px;
    }

    div > img {
      height: 16px;
      width: 16px;
    }
  }

  ${({ counterVisible }) =>
    !counterVisible &&
    `
      width:100% !important;
  `}
`;

const ButtonContainer = styled(FlexElem)`
  /* @media screen and (960px<width<1040px) {
    display: grid;
  } */

  @media screen and (width<700px) {
    display: grid;
  }
`;

const Container = styled(ContentContainerElem)`
  padding: 0;
  gap: 12px;
  width: auto;

  p > span {
    white-space: nowrap;
  }
  p {
    white-space: nowrap;
    word-break: normal;
    overflow-wrap: normal;
  }
  @media screen and (width<700px) {
    gap: ${Spacing(3)};
    background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};

    p > span {
      white-space: wrap;
      font-size: 11px;
      font-weight: 500;
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_THIRD]};
    }
    p {
      white-space: wrap;

      text-align: center;
    }
  }
`;
