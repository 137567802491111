import React, { useState } from 'react';
import { GridElem } from '../../../common/grid';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { MESSAGE_ITEM_PROPS } from '../constant';
import { AvatarElem } from '../../../common/avatar';
import { FlexElem } from '../../../common/flex';
import { TextElem } from '../../../common/text';
import { Modal } from '../../../common/modal/elem';
import { ReactComponent as CloseIcon } from '../../../asset/svg/common/close.svg';
import { ReactComponent as ReplyIcon } from '../../../asset/svg/session/room/chat/reply.svg';
import { COLOR_ENUM } from '../../../theme/color';
import { MarkdownElem } from '../../../common/markdown';
import { ChipElem } from '../../../common/chip';
import { ReactComponent as StarIcon } from '../../../asset/svg/session/star.svg';

export const scrollToMessage = (messageId: string) => {
  const element = document.getElementById(messageId);
  if (element) {
    element.scrollIntoView({ block: 'center' });
  }
};

const Elem: React.FC<MESSAGE_ITEM_PROPS> = ({
  data,
  setReply,
  elemId,
  children,
  hideAvatar = false,
}) => {
  const {
    content,
    id,
    createDate,
    replyTo,
    user,
    nameColor,
    name,
    replyName,
    replyNameColor,
    phoneNumber,
    photo,
    location,
    viewer,
    reservation,
  } = data;

  const [modalVisible, setModalVisible] = useState(false);
  const openModal = () => {
    if (viewer) {
      setModalVisible(true);
    }
  };

  const closeModal = () => {
    if (!user) {
      setModalVisible(false);
    }
  };

  const handleSetReply = () => {
    setReply({ id, content: content.trim() + '...', name });
  };

  return (
    <Wrapper id={elemId} key={id} isActive={reservation}>
      <Container>
        <ModalStyled isOpen={modalVisible} onDidDismiss={closeModal}>
          <ModalContent spacing={3}>
            <AvatarElem size={76} userAvatar={photo} className="avatar" />

            <ModalText spacing={5}>
              <GridElem spacing={2}>
                <TextElem
                  type="semi-bold"
                  size="main"
                  color={nameColor ?? 'textDefault'}
                >
                  {name}
                </TextElem>
                <TextElem size="semiSmall" color="textPrimary">
                  {phoneNumber}
                </TextElem>
                <TextElem type="medium" color="textDefault">
                  {location}
                </TextElem>
              </GridElem>
              <CloseIconStyled onClick={closeModal} />
            </ModalText>
          </ModalContent>
        </ModalStyled>
        <ModalWraper onClick={openModal}>
          {!hideAvatar && (
            <AvatarElem size={20} userAvatar={photo} className="avatar" />
          )}
        </ModalWraper>
        <GridElem spacing={1}>
          <HeadContainer>
            <ModalWraper onClick={openModal}>
              <TextElem
                type="medium"
                color={nameColor ?? 'textPrimary'}
                size="mobileDefault"
                oneLine
              >
                {name}
              </TextElem>
            </ModalWraper>
            <FlexElem
              spacing={2}
              style={{ width: '100%', justifyContent: 'end' }}
            >
              {children}
              {reservation && (
                <ChipElem color="active" style={{ padding: '0 6px' }}>
                  <FlexElem spacing={0.5}>
                    <TextElem
                      tid="Бронь"
                      type="semi-bold"
                      color="active"
                      size="verySmall"
                    />
                    <Star />
                  </FlexElem>
                </ChipElem>
              )}
              <div style={{ minWidth: '40px' }}>
                <TextElem
                  type="medium"
                  color="textPrimary"
                  size="mobileDefault"
                >
                  {createDate}
                </TextElem>
              </div>
            </FlexElem>
          </HeadContainer>

          {replyTo && (
            <ReplyToContainer onClick={() => scrollToMessage(replyTo.id)}>
              <GridElem spacing={1}>
                <FlexElem spacing={1}>
                  <TextElem
                    type="medium"
                    color={replyNameColor ?? 'textPrimary'}
                    size="mobileDefault"
                  >
                    {replyName}
                  </TextElem>
                </FlexElem>

                <TextElem
                  color="textFourth"
                  style={{ wordBreak: 'break-word' }}
                  size="mobileDefault"
                >
                  {replyTo.content.trim() + '...'}
                </TextElem>
              </GridElem>
            </ReplyToContainer>
          )}
          {user?.id ? (
            <MarkdownElem
              color="textFourth"
              style={{ wordBreak: 'break-word' }}
              size="mobileDefault"
              text={content}
            />
          ) : (
            <TextElem
              color="textFourth"
              style={{ wordBreak: 'break-word' }}
              size="mobileDefault"
            >
              {content}
            </TextElem>
          )}
        </GridElem>

        <ReplyContainer onClick={handleSetReply}>
          <ReplyIcon />
        </ReplyContainer>
      </Container>
    </Wrapper>
  );
};

const Star = styled(StarIcon)`
  height: 8px;
  width: 8px;
  margin-bottom: 1px;
`;

const ModalWraper = styled.div`
  cursor: pointer;
`;

const ReplyToContainer = styled.div`
  border-radius: 10px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(3)};
  width: fit-content;
`;

export const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

const ReplyContainer = styled.div`
  padding-top: 3px;
  cursor: pointer;
  height: 100%;
  :hover {
    svg {
      path {
        transition: all 0.2s;
      }

      path {
        stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      }
    }
  }
`;

const ModalStyled = styled(Modal)`
  ::part(content) {
    padding: ${Spacing(6)} ${Spacing(5)};
    min-width: 300px;
    width: fit-content;
    top: auto;
  }
  & > div > div {
    flex-direction: row;
  }

  @media screen and (width<700px) {
    ::part(content) {
      width: calc(100% - 40px);
    }
  }
`;

const ModalContent = styled(GridElem)`
  grid-template-columns: 76px auto;
`;

const ModalText = styled(GridElem)`
  grid-template-columns: auto 16px;
`;

export const HeadContainer = styled(FlexElem)`
  justify-content: space-between;
`;

export const Container = styled(GridElem)`
  grid-template-columns: 20px auto min-content;
  gap: ${Spacing(2)};

  @media screen and (width<420px) {
    span {
      font-size: 11px !important;
    }
    .avatar {
      height: 16px !important;
      width: 16px !important;
    }
  }
`;

const Wrapper = styled(GridElem)<{ isActive: boolean }>`
  grid-template-columns: auto;
  gap: ${Spacing(3)};
  padding: 8px 20px;
  background: ${({ isActive }) =>
    isActive
      ? 'linear-gradient(90deg, rgba(199, 158, 102, 0.05) 0%, rgba(240, 208, 157, 0.05) 60%, rgba(235, 204, 149, 0.05) 100%)'
      : 'transparent'};
`;

export { Elem as MessageItemElem };
