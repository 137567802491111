import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { i18n } from '../../lib/lang';
import { FileItemUploadContainer } from '../file-item-upload';
import { VIEWER_ITEM_DATA } from '../../data/viewer/constant';
import { TextElem } from '../../common/text';
import { DividerElem } from '../../common/divider';
import { CheckBoxElem } from '../../common/checkbox';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { FlexElem } from '../../common/flex';
import { ReactComponent as ViewerIcon } from '../../asset/svg/session/profile.svg';
import { ReactComponent as StarIcon } from '../../asset/svg/session/star.svg';
import { ModalElem } from '../../common/modal';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  onSuccessUpload: Function;
  data?: VIEWER_ITEM_DATA;
  modalOpen: () => void;
  modalClose: () => void;
  modalVisible: boolean;
  viewed: boolean;
  changeReaction: Function;
  reactionActive?: boolean;
  defaultCountry?: string;
  reactionVisibility?: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setFieldValue,
  onSuccessUpload,
  data,
  modalOpen,
  modalClose,
  modalVisible,
  viewed = true,
  changeReaction,
  reactionActive,
  defaultCountry,
  reactionVisibility,
}) => {
  return (
    <Container>
      {data?.reservation && (
        <LableContainer>
          <FlexElem spacing={1}>
            <TextElem tid="Бронь" type="semi-bold" color="active" />
            <Star1 />
          </FlexElem>
        </LableContainer>
      )}
      <>
        <Button onClick={modalOpen} viewed={viewed}>
          <ViewerIcon />
        </Button>

        <Modal
          open={modalVisible}
          onClose={modalClose}
          titleTid="SESSION.VIEWER.UPDATE.TITLE"
          closeIcon
        >
          <FormElem onSubmit={formik.handleSubmit}>
            {isLoading && <LoaderElem />}

            <GridElem spacing={5}>
              <GridElem spacing={4}>
                <GridElem
                  spacing={2}
                  style={{ justifyContent: 'center', justifyItems: 'center' }}
                >
                  <TextElem
                    tid="SESSION.VIEWER.UPDATE.FORM.PHOTO"
                    color="textPrimary"
                  />
                  <div style={{ width: '135px' }}>
                    <FileItemUploadContainer
                      onSuccess={onSuccessUpload}
                      defaultValue={data?.photo?.url}
                      name={FORM_VALUE_ENUM.PHOTO}
                      type="image"
                      maxSize={3145728}
                      maxSizeText="3 MB"
                      description={false}
                      publicUrl
                    />
                  </div>
                </GridElem>
                <FieldTextElem
                  name={FORM_VALUE_ENUM.NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="SESSION.VIEWER.UPDATE.FORM.NAME"
                  value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.NAME)}
                />

                <FieldTextElem
                  name={FORM_VALUE_ENUM.PHONE}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="SESSION.VIEWER.UPDATE.FORM.PHONE"
                  value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
                  error={isFieldError(FORM_VALUE_ENUM.PHONE)}
                  type="phone"
                  defaultCountry={defaultCountry}
                />
                <DividerElem />
                {reactionVisibility && (
                  <CheckboxContainer>
                    <CheckBoxElem
                      tid="SESSION.VIEWER.UPDATE.FORM.ANIMATION"
                      checked={reactionActive}
                      onClick={changeReaction}
                    />
                  </CheckboxContainer>
                )}

                <ButtonElem
                  type="submit"
                  tid="SESSION.VIEWER.UPDATE.FORM.BUTTON"
                  disabled={isSubmitDisabled()}
                  color="success"
                />
              </GridElem>
            </GridElem>

            {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
          </FormElem>
        </Modal>
      </>
    </Container>
  );
};

const Star1 = styled(StarIcon)`
  margin-bottom: 3px;
  @media screen and (width<460px) {
    height: 8px;
    width: 8px;
    margin-bottom: 1px;
  }
`;

const Container = styled(FlexElem)`
  width: auto;
  gap: 12px;
  @media screen and (width<460px) {
    gap: 8px;
  }
`;

const LableContainer = styled(FlexElem)`
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.ACTIVE]};
  background: linear-gradient(
    90deg,
    rgba(199, 158, 102, 0.1) 0%,
    rgba(240, 208, 157, 0.1) 60%,
    rgba(235, 204, 149, 0.1) 100%
  );
  width: auto;
  border-radius: 12px;
  height: 50px;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  @media screen and (width<460px) {
    padding: 0 10px;
    height: 36px;

    border-radius: 12px;
  }
`;

const Modal = styled(ModalElem)`
  ::part(content) {
    @media screen and (width<700px) {
      width: 100%;
    }
    width: 320px;
  }
`;

const Button = styled(FlexElem)<{ viewed: boolean }>`
  ::before {
    content: '';
    display: ${(props) => (props.viewed ? 'none' : 'block')};
    position: absolute;
    height: 10px;
    width: 10px;
    border: 2px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
    background: ${({ theme }) => theme[COLOR_ENUM.BLUE]};
    border-radius: 100%;
    top: -5px;
    right: -5px;
  }
  position: relative;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  background: ${({ theme }) => theme[COLOR_ENUM.HOVER]};
  border-radius: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  :hover {
    path {
      fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
  path {
    transition: all 0.2s;
  }
  @media screen and (width<460px) {
    height: 36px;
    width: 36px;
    svg {
      height: 16px;
      width: 16px;
      min-width: 16px;
    }
  }
`;

const CheckboxContainer = styled.div`
  height: 48px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};
  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  padding: 0 ${Spacing(4)};
  border-radius: 10px;
  display: flex;
  align-items: center;
  ion-checkbox {
    z-index: 0;
  }
`;
