export const MODULE_NAME = 'FILE_ITEM_UPLOAD_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  FILE = 'file',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.FILE]: any;
}

export const API = {
  MAIN: {
    TYPE: 'POST',
    URL: '/file',
    PUBLIC_URL: '/file/public',
  },
};

export const allowedImageTypes = [
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif',
  'image/x-icon',
  'image/vnd.microsoft.icon',
];
export const allowedVideoTypes = ['video/mp4', 'video/quicktime'];
export const allowedFileTypes = [
  ...allowedImageTypes,
  ...allowedVideoTypes,
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export interface ACTION_RESPONSE_INTER {
  accessToken: string;
}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
