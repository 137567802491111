import styled, { css } from 'styled-components';
import { GridElem } from '../../../common/grid';
import { SelectDynamicContainer } from '../../select-dynamic';
import { FormikValues } from 'formik';
import { FORM_VALUE_ENUM } from '../constant';
import { ContentContainerElem } from '../../../common/content-container';
import { Spacing } from '../../../theme';
import { FieldDataTimeRangeElem } from '../../../common/field-datetime-range';
import { ButtonElem } from '../../../common/button';
import { convertSessionListToSelect } from '../../../data/session/convert';
import { getByWebinarSessionList } from '../../../data/session/action';
import { convertWebinarListToSelect } from '../../../data/webinar/convert';
import UploadIcon from '../../../asset/svg/analytic/upload.svg';
import UploadIconLight from '../../../asset/svg/analytic/upload-light.svg';
import CloseIcon from '../../../asset/svg/analytic/close.svg';
import { getProjectList } from '../../../data/project/action';
import { convertProjectListToSelect } from '../../../data/project/convert';
import { getWebinarList } from '../../../data/webinar/action';
import { useSelector } from '../../../lib/store';
import { THEME_MODULE_NAME } from '../../../data/theme';
import { THEME_ENUM } from '../../../data/theme/constant';
import { FlexElem } from '../../../common/flex';
import dayjs from 'dayjs';
import { useState } from 'react';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text';

export const AnalyticFilterContainer: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  onRemove: Function;
}> = ({ formik, isFieldError, getFieldError, getFieldValue, onRemove }) => {
  const getWebinarParam = (project: string[]) => {
    return `${project.map((item: any) => `&project=${item}`).join('')}`;
  };

  const getSessionParam = (webinar: string[]) => {
    return `${webinar.map((item: any) => `&webinar=${item}`).join('')}`;
  };
  // const setRange = (value: string) => {
  //   formik.setFieldValue(FORM_VALUE_ENUM.RANGE, value);
  // };

  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  const [activeButton, setActiveButton] = useState('month');
  const now = dayjs();
  const getDateRange = (type: string): [string, string] => {
    switch (type) {
      case 'today':
        return [
          now.startOf('day').format('YYYY-MM-DD'),
          now.endOf('day').format('YYYY-MM-DD'),
        ];
      case 'yesterday':
        return [
          now.subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
          now.endOf('day').format('YYYY-MM-DD'),
        ];
      case 'last7days':
        return [
          now.subtract(7, 'day').startOf('day').format('YYYY-MM-DD'),
          now.endOf('day').format('YYYY-MM-DD'),
        ];
      case 'month':
        return [
          now.startOf('month').startOf('day').format('YYYY-MM-DD'),
          now.endOf('day').format('YYYY-MM-DD'),
        ];
      default:
        return [
          now.startOf('month').startOf('day').format('YYYY-MM-DD'),
          now.endOf('day').format('YYYY-MM-DD'),
        ];
    }
  };
  return (
    <>
      <ContentContainerElem spacing={6}>
        <FiltertContainer spacing={3}>
          <SelectDynamic
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
            error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
            action={getProjectList}
            convert={convertProjectListToSelect}
            // dynamic
            name={FORM_VALUE_ENUM.PROJECT}
            isMulti
          />
          <SelectDynamic
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.WEBINAR)}
            error={isFieldError(FORM_VALUE_ENUM.WEBINAR)}
            name={FORM_VALUE_ENUM.WEBINAR}
            action={getWebinarList}
            convert={convertWebinarListToSelect}
            isMulti
            param={getWebinarParam(getFieldValue(FORM_VALUE_ENUM.PROJECT))}
          />
          <SelectDynamic
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.SESSION)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.SESSION)}
            error={isFieldError(FORM_VALUE_ENUM.SESSION)}
            name={FORM_VALUE_ENUM.SESSION}
            action={getByWebinarSessionList}
            convert={convertSessionListToSelect}
            isMulti
            param={getSessionParam(getFieldValue(FORM_VALUE_ENUM.WEBINAR))}
          />

          <ButtonElem
            onClick={formik.handleSubmit}
            iconLeft={
              theme.type == THEME_ENUM.LIGHT ? UploadIconLight : UploadIcon
            }
            iconSize={20}
            style={{ padding: 0 }}
            color="backgroundThird"
          />
          <ButtonElem
            color="error"
            // fill="icon"
            style={{ padding: 0 }}
            iconLeft={CloseIcon}
            iconSize={20}
            onClick={onRemove}
          />
        </FiltertContainer>
        <FiltertContainerMobile spacing={3}>
          <GridElem
            style={{
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '8px',
            }}
          >
            <SelectDynamic
              formik={formik}
              value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
              error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
              action={getProjectList}
              convert={convertProjectListToSelect}
              dynamic
              name={FORM_VALUE_ENUM.PROJECT}
              isMulti
            />
            <SelectDynamic
              formik={formik}
              value={getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.WEBINAR)}
              error={isFieldError(FORM_VALUE_ENUM.WEBINAR)}
              name={FORM_VALUE_ENUM.WEBINAR}
              action={getWebinarList}
              convert={convertWebinarListToSelect}
              isMulti
              dynamic
              param={getWebinarParam(getFieldValue(FORM_VALUE_ENUM.PROJECT))}
            />
          </GridElem>
          <Grid>
            <SelectDynamicContainer
              formik={formik}
              value={getFieldValue(FORM_VALUE_ENUM.SESSION)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.SESSION)}
              error={isFieldError(FORM_VALUE_ENUM.SESSION)}
              name={FORM_VALUE_ENUM.SESSION}
              action={getByWebinarSessionList}
              convert={convertSessionListToSelect}
              isMulti
              dynamic
              param={getSessionParam(getFieldValue(FORM_VALUE_ENUM.WEBINAR))}
            />
            <ButtonElem
              onClick={formik.handleSubmit}
              iconLeft={
                theme.type == THEME_ENUM.LIGHT ? UploadIconLight : UploadIcon
              }
              iconSize={20}
              style={{ padding: 0 }}
              color="backgroundThird"
            />
            <ButtonElem
              color="error"
              style={{ padding: 0 }}
              iconLeft={CloseIcon}
              iconSize={20}
              onClick={onRemove}
            />
          </Grid>
        </FiltertContainerMobile>
        <DataFilter spacing={3}>
          <DateFilterContainer>
            <ButtonStyled
              onClick={() => {
                formik.setFieldValue(
                  FORM_VALUE_ENUM.RANGE,
                  getDateRange('today'),
                );
                setActiveButton('today');
              }}
              isActive={activeButton === 'today'}
            >
              <TextElem tid="ANALYTICS.DATE_FILTER.TODAY" />
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                formik.setFieldValue(
                  FORM_VALUE_ENUM.RANGE,
                  getDateRange('yesterday'),
                );
                setActiveButton('yesterday');
              }}
              isActive={activeButton === 'yesterday'}
            >
              <TextElem tid="ANALYTICS.DATE_FILTER.YESTERDAY" />
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                formik.setFieldValue(
                  FORM_VALUE_ENUM.RANGE,
                  getDateRange('last7days'),
                );
                setActiveButton('last7days');
              }}
              isActive={activeButton === 'last7days'}
            >
              <TextElem tid="ANALYTICS.DATE_FILTER.WEEK" />
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                formik.setFieldValue(
                  FORM_VALUE_ENUM.RANGE,
                  getDateRange('month'),
                );
                setActiveButton('month');
              }}
              isActive={activeButton === 'month'}
            >
              <TextElem tid="ANALYTICS.DATE_FILTER.MONTH" />
            </ButtonStyled>
          </DateFilterContainer>

          <FieldDataTimeRange
            showTime={false}
            onChange={formik.handleChange}
            name={FORM_VALUE_ENUM.RANGE}
            value={getFieldValue(FORM_VALUE_ENUM.RANGE)}
          />
        </DataFilter>
      </ContentContainerElem>
    </>
  );
};

const ButtonStyled = styled.div<{ isActive: boolean }>`
  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  span {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_THIRD]};
  }
  height: 46px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  ${({ isActive }) => {
    if (isActive) {
      return css`
        background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
        span {
          color: ${({ theme }) => theme[COLOR_ENUM.ACTIVE]};
        }
        border: 1px solid ${({ theme }) => theme[COLOR_ENUM.ACTIVE]};
      `;
    } else {
      return css`
        :hover {
          background: ${({ theme }) => theme[COLOR_ENUM.BORDER]};
          span {
            color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
          }
        }
      `;
    }
  }}
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
`;

const DataFilter = styled(GridElem)`
  grid-template-columns: auto 300px;
  @media screen and (width<=750px) {
    grid-template-columns: 1fr;
  }
`;

const FieldDataTimeRange = styled(FieldDataTimeRangeElem)``;

const SelectDynamic = styled(SelectDynamicContainer)`
  div:first-child {
    /* max-width: 180px; */
  }
`;

const FiltertContainerMobile = styled(GridElem)`
  display: grid;
  @media screen and (width>780px) {
    display: none;
  }
`;

const DateFilterContainer = styled(GridElem)`
  gap: ${Spacing(2)};
  grid-template-columns: repeat(4, 1fr);
  @media screen and (width<=550px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Grid = styled(GridElem)`
  display: grid;
  gap: ${Spacing(2)};
  grid-template-columns: auto 46px 46px;
`;

const FiltertContainer = styled(GridElem)`
  @media screen and (width<=780px) {
    display: none;
  }
  grid-template-columns: repeat(3, 1fr) 46px 46px;
`;
