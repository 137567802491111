import { FILE_ITEM_DATA_RAW } from '../file/constant';
import { PERSON_ITEM_DATA_RAW } from '../person/constant';
import { SESSION_ITEM_DATA_RAW } from '../session/constant';
import { WEBINAR_ITEM_DATA_RAW } from '../webinar/constant';
import { ReactComponent as MessageIcon } from '../../asset/svg/event/message.svg';
import { ReactComponent as GroupMessageIcon } from '../../asset/svg/event/groupMessage.svg';
import { ReactComponent as ButtonIcon } from '../../asset/svg/event/button.svg';
import { ReactComponent as ReactionIcon } from '../../asset/svg/event/reaction.svg';
import { ReactComponent as SurveyIcon } from '../../asset/svg/event/survey.svg';
import { ReactComponent as BannerIcon } from '../../asset/svg/event/banner.svg';
import { ReactComponent as ReservationIcon } from '../../asset/svg/event/reservation.svg';
import { ReactComponent as TimecodeIcon } from '../../asset/svg/event/timecode.svg';
import { ReactComponent as QuestionIcon } from '../../asset/svg/event/question.svg';
import { ReactComponent as ReservationCreatedIcon } from '../../asset/svg/event/reservation-created.svg';

import { MESSAGE_ITEM_DATA_RAW } from '../message/constant';

export enum EVENT_TYPE {
  MESSAGE = 'MESSAGE',
  GROUP_MESSAGE = 'GROUP_MESSAGE',
  BUTTON = 'BUTTON',
  REACTION = 'REACTION',
  BANNER = 'BANNER',
  RESERVATION = 'RESERVATION',
  TIMECODE = 'TIMECODE',
  RESERVATION_CREATED = 'RESERVATION_CREATED',
  QUESTION = 'QUESTION',
  SURVEY = 'SURVEY',
}

export const EVENT_IMG_DATA = {
  [EVENT_TYPE.MESSAGE]: MessageIcon,
  [EVENT_TYPE.GROUP_MESSAGE]: GroupMessageIcon,
  [EVENT_TYPE.BUTTON]: ButtonIcon,
  [EVENT_TYPE.REACTION]: ReactionIcon,
  [EVENT_TYPE.SURVEY]: SurveyIcon,
  [EVENT_TYPE.RESERVATION]: ReservationIcon,
  [EVENT_TYPE.BANNER]: BannerIcon,
  [EVENT_TYPE.TIMECODE]: TimecodeIcon,
  [EVENT_TYPE.QUESTION]: QuestionIcon,
  [EVENT_TYPE.RESERVATION_CREATED]: ReservationCreatedIcon,
};
export interface REACTION_TYPE {
  emoji: string;
  quantity: string;
  currentQuantity?: number;
}

export interface ANSWER_TYPE {
  text: string;
  quantity: string;
  currentQuantity?: number;
}

export interface SURVEY_RAW_TYPE {
  question: string;
  answers: { [viewerId: string]: number };
  options: {
    text: string;
    quantity: number;
    percentage: number;
    currentQuantity: number;
  }[];
}

export interface SURVEY_TYPE {
  question: string;
  answer: number;
  options: {
    text: string;
    quantity: number;
    percentage: number;
    currentQuantity: number;
  }[];
}

export interface QUESTION_TYPE {
  name: string;
  message: string;
}

export interface RESERVATION_CREATED_TYPE {
  name: string;
  city: string;
  country: string;
  photo: FILE_ITEM_DATA_RAW;
  photoUrl: string;
}
export interface REACTION_RAW_TYPE {
  reaction: { [emoji: string]: REACTION_TYPE };
}

export interface EVENT_ITEM_DATA_RAW {
  id: string;
  time: string;
  message: string;
  type: EVENT_TYPE;
  file: FILE_ITEM_DATA_RAW;
  person: PERSON_ITEM_DATA_RAW;
  webinar: WEBINAR_ITEM_DATA_RAW;
  session: SESSION_ITEM_DATA_RAW;
  startTime: string;
  endTime: string;
  buttonText: string;
  placeAvailable: number;
  placeReserved: number;
  timeBoost: string;
  banner: FILE_ITEM_DATA_RAW;
  link: string;
  buttonColor: string;
  textColor: string;
  messageReply: MESSAGE_ITEM_DATA_RAW;
  messages: { user: string; time: string; message: string }[];
  reaction: REACTION_RAW_TYPE;
  answers: ANSWER_TYPE[];
  fileUrl: string;
  isActive: boolean;
  counterVisible: boolean;
}

export interface EVENT_ITEM_LIST_DATA_RAW {
  list: EVENT_ITEM_DATA_RAW[];
}

export interface EVENT_ITEM_DATA extends Omit<EVENT_ITEM_DATA_RAW, 'reaction'> {
  status?: string;
  TypeIcon: any;
  emojiList: string;
  reactionCounter: number;
  reaction: REACTION_TYPE[];
}

export interface EVENT_ITEM_LIST_DATA {
  list: EVENT_ITEM_DATA[];
  isEmpty: boolean;
}

export const EMOJI_OPTION_LIST = [
  {
    value: '❤️',
    label: `❤️`,
  },
  {
    value: '😂',
    label: `😂`,
  },
  {
    value: '🔥',
    label: `🔥`,
  },
  {
    value: '😍',
    label: `😍`,
  },
  {
    value: '👍',
    label: '👍',
  },
  {
    value: '👎',
    label: '👎',
  },
  {
    value: '🤯',
    label: '🤯',
  },
  {
    value: '🤔',
    label: '🤔',
  },
  {
    value: '🤡',
    label: '🤡',
  },
  {
    value: '🤮',
    label: '🤮',
  },
  {
    value: '🤦‍♀️',
    label: '🤦‍♀️',
  },
  {
    value: '💩',
    label: '💩',
  },
];
