import React, { useEffect, useState } from 'react';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';

import { useMutation, useQuery } from 'react-query';
import { Component } from './component';
import {
  SESSION_ITEM_DATA,
  SESSION_ITEM_DATA_RAW,
} from '../../data/session/constant';
import { convertSession } from '../../data/session/convert';
import { getSession } from '../session-data/action';
import { UTMObject } from '../../data/viewer/constant';
import { connect } from './action';

export const Container: React.FC<{ sessionId: string; query: UTMObject }> = ({
  sessionId,
  query,
}) => {
  const [date, setDate] = useState(new Date().toISOString());
  const preFetch = useQuery(MODULE_NAME, () => getSession(sessionId), {
    refetchInterval: 30000,
  });

  const connectAction = useMutation(() => connect(sessionId, date));

  const getData = (): SESSION_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as SESSION_ITEM_DATA_RAW;

    if (data) {
      return convertSession(data);
    }
  };

  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const data = getData();
  // useEffect(() => {
  //   if (data?.sessionIsOver) {
  //     history.push(SESSION_ITEM_OVER_PAGE_PATH_DYNAMIC(data.id));
  //   }
  // }, [data]);

  useEffect(() => {
    if (data?.sessionIsOver) {
      connectAction.mutate();
    }
    if (data?.webinar?.project?.favicon?.url) {
      const link = document.querySelector(
        "link[rel~='icon']",
      ) as HTMLLinkElement;
      if (link) {
        link.href = data?.webinar?.project?.favicon?.url;
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = data?.webinar?.project?.favicon?.url;
        document.head.appendChild(newLink);
      }
    }
    if (data?.webinar?.pageTitle) {
      document.title = data?.webinar?.pageTitle;
    }
  }, [preFetch.data]);

  return (
    <Component
      data={data}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      sessionId={sessionId}
      query={query}
      date={date}
    />
  );
};
