import React, { useEffect, useState } from 'react';

import { GridElem } from '../../common/grid';

import styled, { css } from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { ModalElem } from '../../common/modal';
import { FormikValues } from 'formik';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { i18n } from '../../lib/lang';
import { WEBINAR_ITEM_DATA_RAW } from '../../data/webinar/constant';
import { VIEWER_ITEM_DATA } from '../../data/viewer/constant';
import { PaymentConfirm } from './frame/paymentConfirm';
import { ReservationCreate } from './frame/reservationCreate';
import { PaymentCreate } from './frame/paymentCreate';
import { ReservationOpen } from './frame/reservationOpen';
import { ContentContainerElem } from '../../common/content-container';
import { Skeleton } from './frame/skeleton';
import { EVENT_ITEM_DATA_RAW } from '../../data/event/constant';
import { FORM_VALUE_ENUM } from './constant';
import { ProgressElem } from './frame/progress';
import { ProgressTitleElem } from './frame/progressTitle';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled?: boolean;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  formVisible: boolean;
  setFormVisible: Function;
  modal: boolean;
  webinarData?: WEBINAR_ITEM_DATA_RAW;
  data?: VIEWER_ITEM_DATA;
  isSuccessFecth?: boolean;
  event?: EVENT_ITEM_DATA_RAW;
  reservationButtonClick?: Function;
  defaultCountry: string;
  isOver?: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  formVisible,
  setFormVisible,
  modal,
  webinarData,
  data,
  isSuccessFecth,
  event,
  reservationButtonClick,
  defaultCountry,
  isOver,
}) => {
  const name = getFieldValue(FORM_VALUE_ENUM.NAME);
  const phone = getFieldValue(FORM_VALUE_ENUM.PHONE);
  const [modalVisible, setModalVisible] = useState(false);

  const modalOpen = (e: any) => {
    e.stopPropagation();
    if (reservationButtonClick) {
      reservationButtonClick();
    }
    setModalVisible(true);
  };

  const modalClose = (e: any) => {
    setModalVisible(false);
  };

  const getModalTitle = () => {
    if (data?.reservation && !data.reservation?.payment?.confirm) {
      return ProgressTitleElem;
    }
  };

  useEffect(() => setFormVisible(!(name && phone)), [data]);

  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
      <>
        {modal ? (
          <>
            {event?.id && (
              <>
                <ReservationOpen modalOpen={modalOpen} event={event} />
                <Modal
                  open={modalVisible}
                  onClose={modalClose}
                  Title={getModalTitle()}
                  closeIcon
                  bigSize={
                    data?.reservation && !data.reservation?.payment?.confirm
                  }
                >
                  <ModalContent spacing={5} style={{ textAlign: 'center' }}>
                    {isSuccessFecth && webinarData ? (
                      <ModalContent spacing={5} style={{ textAlign: 'center' }}>
                        {!data?.reservation && (
                          <ReservationCreate
                            formik={formik}
                            isFieldError={isFieldError}
                            getFieldError={getFieldError}
                            getFieldValue={getFieldValue}
                            name={name}
                            phone={phone}
                            setFormVisible={setFormVisible}
                            formVisible={formVisible}
                            webinarData={webinarData}
                            isSubmitDisabled={isSubmitDisabled}
                            event={event}
                            defaultCountry={defaultCountry}
                          />
                        )}
                        {data?.reservation &&
                          !data.reservation?.payment?.confirm && (
                            <GridElem>
                              <ProgressContainer>
                                <ProgressContent />
                              </ProgressContainer>
                              <PaymentCreate
                                formik={formik}
                                isFieldError={isFieldError}
                                getFieldError={getFieldError}
                                getFieldValue={getFieldValue}
                                name={name}
                                phone={phone}
                                setFormVisible={setFormVisible}
                                formVisible={formVisible}
                                webinarData={webinarData}
                                isSubmitDisabled={isSubmitDisabled}
                                reservationId={data.reservation.id}
                              />
                            </GridElem>
                          )}
                        {data?.reservation?.payment?.confirm && (
                          <PaymentConfirm webinarData={webinarData} />
                        )}
                      </ModalContent>
                    ) : (
                      <Skeleton />
                    )}
                  </ModalContent>
                </Modal>
              </>
            )}
          </>
        ) : (
          <GridElem>
            {data?.reservation && !data?.reservation?.payment?.confirm && (
              <ProgressElem />
            )}
            <ContentContainer
              bigSize={data?.reservation && !data.reservation?.payment?.confirm}
            >
              {isSuccessFecth && webinarData ? (
                <ModalContent spacing={5} style={{ textAlign: 'center' }}>
                  {!data?.reservation && (
                    <ReservationCreate
                      formik={formik}
                      isFieldError={isFieldError}
                      getFieldError={getFieldError}
                      getFieldValue={getFieldValue}
                      name={name}
                      phone={phone}
                      setFormVisible={setFormVisible}
                      formVisible={formVisible}
                      webinarData={webinarData}
                      isSubmitDisabled={isSubmitDisabled}
                      defaultCountry={defaultCountry}
                      isOver={isOver}
                    />
                  )}
                  {data?.reservation && !data.reservation?.payment?.confirm && (
                    <PaymentCreate
                      formik={formik}
                      isFieldError={isFieldError}
                      getFieldError={getFieldError}
                      getFieldValue={getFieldValue}
                      name={name}
                      phone={phone}
                      setFormVisible={setFormVisible}
                      formVisible={formVisible}
                      webinarData={webinarData}
                      isSubmitDisabled={isSubmitDisabled}
                      reservationId={data.reservation.id}
                    />
                  )}
                  {data?.reservation?.payment?.confirm && (
                    <PaymentConfirm webinarData={webinarData} />
                  )}
                </ModalContent>
              ) : (
                <Skeleton isOver={isOver} />
              )}
            </ContentContainer>
          </GridElem>
        )}
      </>
    </>
  );
};

const ProgressContainer = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
`;

const ProgressContent = styled.div`
  width: 90%;
  height: 100%;
  border-radius: 8px 0 0 8px;
  background: ${({ theme }) => theme[COLOR_ENUM.ACTIVE]};
`;

const ContentContainer = styled(ContentContainerElem)<{ bigSize: boolean }>`
  max-width: ${(props) => (props.bigSize ? '689px' : '472px')};
  margin: auto;
  position: relative;
  @media screen and (width<600px) {
    padding: 16px 12px;
  }
`;

export const FormBorder = styled(GridElem)`
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.ACTIVE]};
  padding: ${Spacing(4)};
  @media screen and (width<472px) {
    padding: 12px;
  }
`;

const Modal = styled(ModalElem)<{ bigSize: boolean }>`
  text-align: center;

  .container {
    gap: ${(props) => (props.bigSize ? '8px' : '20px')};
  }
  ::part(content) {
    width: ${(props) => (props.bigSize ? '689px' : '472px')};
    @media screen and (width<700px) {
      width: 472px;
      top: 100px;
      bottom: auto;
      left: auto;
    }
    @media screen and (width<472px) {
      width: 100%;
      top: auto;
      bottom: 0;
      left: 0;
    }

    overflow: hidden;
  }
  #content-container {
    max-height: calc(85dvh - 81px);
    @media screen and (width<472px) {
      max-height: 85dvh;
    }
    scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
      transparent;
    scrollbar-width: thin;
    overflow-y: auto;
  }
`;

const ModalContent = styled(GridElem)`
  @media screen and (width<472px) {
    width: 100%;
    .titleContent {
      font-size: 14px;
    }
  }
`;
