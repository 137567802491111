import React from 'react';
import { GridElem } from '../../../common/grid';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { MESSAGE_ITEM_MODERATION_PROPS } from '../constant';
import { MessageItemElem } from './message-item-list.elem';
import { CheckBoxElem } from '../../../common/checkbox';
import { COLOR_ENUM } from '../../../theme/color';
import { SessionItemModerationMessageContainer } from '../../../epic/session-item-moderation-message';
import { FlexElem } from '../../../common/flex';
import { ChipElem } from '../../../common/chip';
import { Popover } from 'antd';
import { TextElem } from '../../../common/text';
import { ReactComponent as DetailIcon } from '../../../asset/svg/session/moderation/detail.svg';
import { convertDateTime } from '../../../lib/lang/service';

const Elem: React.FC<MESSAGE_ITEM_MODERATION_PROPS> = ({
  onSelect,
  selectList,
  preModerationActive,

  ...props
}) => {
  const isSelected = selectList.messageIds.includes(props.data.id);

  const isSelectedViewer = selectList.viewerIds.includes(
    props.data?.viewer?.id,
  );

  const handleSelect = () =>
    onSelect(props.data.id, isSelectedViewer ? null : props.data?.viewer?.id);

  return (
    <Container
      id={props.elemId}
      isSelected={isSelected}
      isActive={!!props?.data?.viewer?.reservation}
    >
      <MessageItemElem {...props}>
        <FlexElem spacing={1} style={{ justifyContent: 'space-between' }}>
          <FlexElem spacing={1}>
            {props.data?.viewer?.isBanned && (
              <ChipElem tid="Ban" color="error" />
            )}
            {props.data?.viewer?.isMuted && (
              <ChipElem tid="Mute" color="active" />
            )}
          </FlexElem>
          <FlexElem spacing={1} style={{ width: 'min-content' }}>
            {preModerationActive &&
              props?.data?.viewer &&
              !props.data.moderCheck && (
                <SessionItemModerationMessageContainer
                  messageId={props.data.id}
                  sessionId={props.data.session.id}
                />
              )}
            {preModerationActive && (
              <PopoverContainer id="popover-container">
                <PopoverStyled
                  //@ts-ignore
                  content={() => (
                    <GridElem>
                      <TextElem>{props.data.session.name}</TextElem>
                      <TextElem>
                        {convertDateTime(props.data.session.serverDate)}
                      </TextElem>
                    </GridElem>
                  )}
                  overlayStyle={{
                    width: 'calc(100% - 40px)',
                    maxWidth: '430px',
                  }}
                  //@ts-ignore
                  getPopupContainer={() =>
                    document.getElementById('popover-container')
                  }
                >
                  <DetailIcon />
                </PopoverStyled>
              </PopoverContainer>
            )}
          </FlexElem>
          {props?.data?.viewer && (
            <CheckBox checked={isSelected} onClick={handleSelect} />
          )}
        </FlexElem>
      </MessageItemElem>
    </Container>
  );
};

const PopoverContainer = styled.div`
  .ant-popover-content {
    width: calc(100% - 40px);
    max-width: 430px;
  }
  .ant-popover-inner {
    padding: ${Spacing(4)};
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  }
  /* z-index: ; */
  .ant-popover-arrow {
    ::before {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
    }
  }
  .ant-popover {
    border-radius: 10px;
  }
`;

const PopoverStyled = styled(Popover)`
  display: flex;
  gap: ${Spacing(1.5)};
`;

const CheckBox = styled(CheckBoxElem)`
  width: min-content !important;
`;

const Container = styled(GridElem)<{ isSelected: boolean; isActive: boolean }>`
  grid-template-columns: auto;
  gap: ${Spacing(3)};
  padding: 8px 20px;
  background: ${({ isActive }) =>
    isActive
      ? 'linear-gradient(90deg, rgba(199, 158, 102, 0.05) 0%, rgba(240, 208, 157, 0.05) 60%, rgba(235, 204, 149, 0.05) 100%)'
      : 'transparent'};
  background: ${({ isSelected, theme }) =>
    isSelected && theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

export { Elem as MessageItemModerationElem };
