import styled from 'styled-components';
import React, { useState } from 'react';
import { COLOR_ENUM } from '../../../theme/color';

import { FieldDataTimeRangeElem } from '../../../common/field-datetime-range';

export const DateRange: React.FC<{
  setDate: Function;
  date: any;
}> = ({ setDate, date }) => {
  const handleChange = (e: any) => {
    setDate(e?.target?.value);
  };
  return (
    <FieldDataTimeRange showTime={false} onChange={handleChange} value={date} />
  );
};

const FieldDataTimeRange = styled(FieldDataTimeRangeElem)`
  .ant-picker-range {
    :hover {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.DEFAULT] === '#1B1924'
          ? theme[COLOR_ENUM.INPUT_BACKGROUND]
          : theme[COLOR_ENUM.INPUT]};
    }
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.DEFAULT] === '#1B1924'
        ? theme[COLOR_ENUM.INPUT_BACKGROUND]
        : theme[COLOR_ENUM.INPUT]};
  }
`;
