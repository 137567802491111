import React from 'react';

import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { GridElem } from '../../common/grid';
import styled from 'styled-components';
import logoIcon from '../../asset/svg/logo.svg';
import logoIconLight from '../../asset/svg/logo-light.svg';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import { THEME_ENUM } from '../../data/theme/constant';
import { SessionItemRoomReservationContainer } from '../session-item-room-reservation';
import { Skeleton } from '../session-item-room-reservation/frame/skeleton';
import { ContentContainerElem } from '../../common/content-container';
import { UTMObject } from '../../data/viewer/constant';
import { AlertActionElem } from '../../common/alert-action';
import { RESERVATION_SOURCE } from '../../data/analytic/constant';
import { TextElem } from '../../common/text';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  sessionId: string;
  query: UTMObject;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  query,
  sessionId,
}) => {
  return (
    <Container spacing={7}>
      {isError && <AlertActionElem tid={errorMessage} type="error" />}
      {isLoading && (
        <ContentContainerElem style={{ width: '472px', margin: 'auto' }}>
          <Skeleton />
        </ContentContainerElem>
      )}
      {isSuccess && data && (
        <GridElem spacing={7}>
          <GridElem>
            {data?.webinar?.logo?.url && (
              <Logo src={data?.webinar?.logo?.url} />
            )}
            {/* <SessionName
              type="bold"
              size={width < 500 ? 'medium' : 'heading'}
              tid="SESSION.ITEM.NAME"
              lineHeight
              tvalue={{ value: data.name }}
            /> */}
          </GridElem>
          <SessionItemRoomReservationContainer
            sessionId={sessionId}
            webinarData={data.webinar}
            query={query}
            source={RESERVATION_SOURCE.OVER_SESSION}
            defaultCountry={data.countryCode}
            isOver
          />
        </GridElem>
      )}
    </Container>
  );
};

const Logo = styled.img`
  margin: auto;
  width: auto;
  height: auto;
`;

const Container = styled(GridElem)`
  /* justify-content: center; */
`;

// const SessionName = styled(TextElem)`
//   text-align: center;
//   line-height: 130%;

//   @media screen and (width<500px) {
//     font-size: ;
//   }
// `;
