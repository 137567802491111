import React from 'react';
import { GridElem } from '../../../common/grid';
import { TextElem } from '../../../common/text';
import { FormBorder } from '../component';
import { SkeletonFieldElem } from '../../../common/skeleton-field';
import { PopoverElem } from './popover';

export const Skeleton: React.FC<{ isOver?: boolean }> = ({ isOver }) => {
  return (
    <GridElem spacing={5} style={{ maxWidth: '472px', margin: 'auto' }}>
      {!isOver && (
        <>
          <TextElem
            style={{ lineHeight: '150%', textAlign: 'center' }}
            size="medium"
            type="semi-bold"
            tid="SESSION.VIEWER.RESERVATION.MODAL.TITLE"
            className="titleContent"
          />
          <SkeletonFieldElem title={false} />
        </>
      )}

      <FormBorder spacing={4}>
        {!isOver && (
          <GridElem spacing={2} style={{ justifyItems: 'center' }}>
            <TextElem
              style={{ lineHeight: '150%' }}
              size="main"
              type="semi-bold"
              color="textSecondary"
              tid="SESSION.VIEWER.RESERVATION.MODAL.DESCRIPTION"
            />
          </GridElem>
        )}
        {isOver && (
          <TextElem
            style={{ lineHeight: '150%', textAlign: 'center' }}
            size="medium"
            type="semi-bold"
            tid="SESSION.VIEWER.RESERVATION.MODAL.TITLE"
            className="titleContent"
          />
        )}
        <GridElem spacing={4}>
          <SkeletonFieldElem />
          <SkeletonFieldElem />
          <SkeletonFieldElem title={false} />
        </GridElem>

        <PopoverElem />
      </FormBorder>
    </GridElem>
  );
};
