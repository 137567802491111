import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { COLOR_TYPE } from '../../theme/color';
import { Spacing } from '../../theme';

export const Elem: React.FC<{
  tid?: string;
  tvalue?: object;
  text?: string;
  children?: ReactNode;
  color?: COLOR_TYPE;
  style?: object;
}> = ({ tid, tvalue, children, color = 'backgroundSecondary', style }) => {
  return (
    <Container color={color} style={style}>
      {(tid || tvalue) && (
        <TextElem
          color={color}
          size="chip"
          type="semi-bold"
          tid={tid}
          tvalue={tvalue}
          oneLine
          style={{ maxWidth: '70px', minWidth: '20px' }}
        />
      )}
      {children}
    </Container>
  );
};

const Container = styled.div<{
  color: COLOR_TYPE;
}>`
  background: ${({ theme, color }) => `${theme[color]}40`};
  padding: ${Spacing(0.5)} ${Spacing(3)};
  margin: auto 0;

  display: flex;
  align-items: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CHIP]}px;
  height: 24px;
  width: fit-content;
`;
