import React from 'react';

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { FieldTextElem } from '../../../common/field-text';
import { FlexElem } from '../../../common/flex';
import styled from 'styled-components';
import { ToggleElem } from '../../../common/toggle';
import { COLOR_ENUM } from '../../../theme/color';

export const ReservationForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
}> = ({ formik, isFieldError, getFieldError, getFieldValue }) => {
  return (
    <GridElem spacing={5}>
      <FieldTextElem
        name={FORM_VALUE_ENUM.BUTTON_TEXT}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="EVENT.FORM.BUTTON_TEXT"
        value={getFieldValue(FORM_VALUE_ENUM.BUTTON_TEXT)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.BUTTON_TEXT)}
        error={isFieldError(FORM_VALUE_ENUM.BUTTON_TEXT)}
      />

      <DoubleContainerElem>
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.START_TIME"
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
          formik={formik}
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME_BOOST}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.TIME_BOOST"
          value={getFieldValue(FORM_VALUE_ENUM.TIME_BOOST)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME_BOOST)}
          error={isFieldError(FORM_VALUE_ENUM.TIME_BOOST)}
          formik={formik}
        />
      </DoubleContainerElem>
      <DoubleContainerElem>
        <FieldTextElem
          type="customNumber"
          name={FORM_VALUE_ENUM.PLACE_AVAILABLE}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="EVENT.FORM.PLACE_AVAILABLE"
          value={getFieldValue(FORM_VALUE_ENUM.PLACE_AVAILABLE)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PLACE_AVAILABLE)}
          error={isFieldError(FORM_VALUE_ENUM.PLACE_AVAILABLE)}
        />
        <FieldTextElem
          type="customNumber"
          name={FORM_VALUE_ENUM.PLACE_RESERVED}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="EVENT.FORM.PLACE_RESERVED"
          value={getFieldValue(FORM_VALUE_ENUM.PLACE_RESERVED)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PLACE_RESERVED)}
          error={isFieldError(FORM_VALUE_ENUM.PLACE_RESERVED)}
        />
      </DoubleContainerElem>
      <ToggleContainer>
        <Toggle
          tid="WEBINAR.UPDATE.FORM.COUNTER_VISIBLE"
          checked={getFieldValue(FORM_VALUE_ENUM.COUNTER_VISIBLE)}
          name={FORM_VALUE_ENUM.COUNTER_VISIBLE}
          handleClick={formik.handleChange}
        />
      </ToggleContainer>
    </GridElem>
  );
};

const Toggle = styled(ToggleElem)`
  height: 20px !important;
  width: 33px !important;
  --handle-width: 15px;
  --handle-height: 15px;
  --handle-spacing: 1.8px;
`;

const ToggleContainer = styled.div`
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: 10px;
  padding: 0 16px;

  width: fit-content;
  height: 46px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  div > div {
    height: 19px;
  }
`;
