import { useEffect } from 'react';
import { useSocket } from '../../data/socket/provider';
import {
  EVENT_ITEM_DATA_RAW,
  QUESTION_TYPE,
  REACTION_RAW_TYPE,
  RESERVATION_CREATED_TYPE,
  SURVEY_RAW_TYPE,
  SURVEY_TYPE,
} from '../../data/event/constant';
import {
  MESSAGE_ITEM_DATA,
  MESSAGE_ITEM_DATA_RAW,
} from '../../data/message/constant';
import { BASE_URL } from '../../lib/http/constant';
import { useQueryClient } from 'react-query';
import { VIEWER_ITEM_DATA_MODULE_NAME } from '../viewer-item-data';
import { convertSurvey } from '../../data/event/convert';
import {
  convertMessageListWithCheck,
  convertMessageWithCheck,
} from '../../data/message/convert';

interface useRoomSocketProps {
  sessionToken: string;
  sessionId: string;
  setChatMessage: React.Dispatch<
    React.SetStateAction<MESSAGE_ITEM_DATA[] | undefined>
  >;
  setSessionStart: React.Dispatch<React.SetStateAction<boolean>>;
  setEventStarted: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionEnd: React.Dispatch<React.SetStateAction<boolean>>;
  setChatActive: React.Dispatch<React.SetStateAction<boolean>>;

  setEvent: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW | undefined>
  >;
  setSurvey: React.Dispatch<React.SetStateAction<SURVEY_TYPE | undefined>>;
  setBanner: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW | undefined>
  >;
  setButton: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW | undefined>
  >;
  setReactions: React.Dispatch<
    React.SetStateAction<REACTION_RAW_TYPE | undefined>
  >;

  setReservationCreated: React.Dispatch<
    React.SetStateAction<RESERVATION_CREATED_TYPE | undefined>
  >;
  setQuestion: React.Dispatch<React.SetStateAction<QUESTION_TYPE | undefined>>;
  viewerBan: () => void;

  setViewerCount: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const useRoomSocket = ({
  sessionToken,
  sessionId,
  setChatMessage,
  setSessionStart,
  setEventStarted,
  setSessionEnd,
  setEvent,
  setBanner,
  setButton,
  setReactions,
  setReservationCreated,
  setQuestion,
  setChatActive,
  setSurvey,
  viewerBan,
  setViewerCount,
}: useRoomSocketProps) => {
  const {
    socket,
    initializeSocket,
    sendMessage,
    sendReaction,
    reservationButtonClick,
    sendAnswer,
  } = useSocket();

  const query = useQueryClient();

  useEffect(() => {
    if (sessionToken) {
      initializeSocket(BASE_URL as string, {
        auth: {
          session_token: sessionToken,
        },
      });
    }
  }, [sessionToken]);

  useEffect(() => {
    if (socket) {
      socket.emit('joinSession', `${sessionId}`);

      socket.on('addPropViewerId', (viewerId: string) => {
        (socket as any).viewerId = viewerId;
      });

      socket.on('sessionMessages', (messages: MESSAGE_ITEM_DATA_RAW[]) => {
        setChatMessage(
          convertMessageListWithCheck(
            messages,
            messages[0]?.session?.auto,
            (socket as any).viewerId,
          ),
        );
      });

      socket.on('chat', (message: MESSAGE_ITEM_DATA_RAW) => {
        const convertedMessage = convertMessageWithCheck(
          message,
          message?.session?.auto,
          (socket as any).viewerId,
        );
        if (convertedMessage) {
          setChatMessage((previousMessages) => {
            if (previousMessages) {
              return [...previousMessages, convertedMessage];
            }
          });
        }
      });

      socket.on('reactionUpdate', (reactionData: REACTION_RAW_TYPE) => {
        setReactions(reactionData);
      });

      socket.on('viewerCount', (viewerCount: number) => {
        setViewerCount(viewerCount);
      });

      socket.on('surveyUpdate', (survayData: SURVEY_RAW_TYPE) => {
        setSurvey(
          survayData
            ? convertSurvey(survayData, (socket as any).viewerId)
            : undefined,
        );
      });

      socket.on('deleteMessageList', (idsToRemove: string[]) => {
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[] | undefined) => {
          return previousMessages?.filter(
            (message) => !idsToRemove?.includes(message.id),
          );
        });
      });

      socket.on('paymentSuccess', () => {
        query.invalidateQueries(VIEWER_ITEM_DATA_MODULE_NAME);
      });

      socket.on('viewerBan', () => {
        viewerBan();
      });

      socket.on('sessionStart', () => {
        setSessionStart(true);
      });

      socket.on('chatActivated', () => {
        setChatActive(true);
      });

      socket.on('chatInactive', () => {
        setChatActive(false);
      });

      socket.on('eventStart', () => {
        setEventStarted(true);
      });

      socket.on('sessionEnd', () => {
        setSessionEnd(true);
      });

      socket.on('showReservation', (event: EVENT_ITEM_DATA_RAW) => {
        setEvent(event);
      });

      socket.on('reservationIncrement', (data: number) => {
        setEvent((event) =>
          event ? { ...event, placeReserved: data } : undefined,
        );
      });

      socket.on('hiddenReservation', (event: EVENT_ITEM_DATA_RAW) => {
        setEvent(undefined);
      });

      socket.on('showButton', (event: EVENT_ITEM_DATA_RAW) => {
        setButton(event);
      });

      socket.on('showBanner', (event: EVENT_ITEM_DATA_RAW) => {
        setBanner(event);
      });

      socket.on('showQuestion', (data: QUESTION_TYPE) => {
        setQuestion(data);
      });

      socket.on('hideQuestion', (data: QUESTION_TYPE) => {
        setQuestion(undefined);
      });

      socket.on('showReservationCreated', (data: RESERVATION_CREATED_TYPE) => {
        setReservationCreated(data);
        setTimeout(() => {
          setReservationCreated((reservationCreated) =>
            reservationCreated?.name === data.name
              ? undefined
              : reservationCreated,
          );
        }, 5000);
      });

      socket.on('hideButton', () => {
        setButton(undefined);
      });

      socket.on('hideBanner', () => {
        setBanner(undefined);
      });

      socket.on('closeReservation', () => {
        setEvent(undefined);
      });

      const handleBeforeUnload = () => {
        socket.disconnect();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        handleBeforeUnload();
      };
    }
  }, [socket]);

  return { sendMessage, reservationButtonClick, sendReaction, sendAnswer };
};

export default useRoomSocket;
