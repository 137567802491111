import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';
import { SelectElem } from '../../common/select';
import { timezonearr } from '../session-item-data/constant';
import { ContentContainerElem } from '../../common/content-container';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

import { ReactComponent as AddIconLarge } from '../../asset/svg/common/add.svg';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import importIconLight from '../../asset/svg/button/import-light-theme.svg';

import addIcon from '../../asset/svg/button/plus.svg';
import importIcon from '../../asset/svg/button/import-light.svg';
import { ToggleElem } from '../../common/toggle';
import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import { FieldTimeInputElem } from '../../common/field-time-input';
import moment, { Moment } from 'moment';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { THEME_ENUM } from '../../data/theme/constant';
import { DoubleContainerElem } from '../../common/double-container';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  handleImport: Function;
  formVisible: boolean;
  setFormVisible: Function;
  setValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setFieldValue,
  handleImport,
  formVisible,
  setFormVisible,
  setValue,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  const formatedTimestamp = () => {
    return new Date().toISOString().slice(0, -8);
  };

  const disabledDate = (current: Moment | null): boolean => {
    // Можно выбрать только даты в будущем
    return current ? current < moment().startOf('day') : false;
  };

  const disabledDateTime = (current: Moment | null) => {
    if (current && current.isSame(moment(), 'day')) {
      const now = moment();
      const hours = now.hours();
      const minutes = now.minutes();

      return {
        disabledHours: () => range(0, 24).splice(0, hours),
        disabledMinutes: (selectedHour: number) => {
          if (selectedHour < hours) {
            // Если выбранный час меньше текущего, блокируем все минуты
            return range(0, 60);
          }
          if (selectedHour === hours) {
            // Если выбранный час равен текущему, блокируем минуты до текущего момента
            return range(0, 60).filter((minute) => minute < minutes);
          }
          // Если выбранный час больше текущего, все минуты доступны
          return [];
        },
      };
    }
    return {};
  };

  // Helper function to generate an array of numbers
  const range = (start: number, end: number) => {
    return Array.from({ length: end - start }, (_, i) => i + start);
  };
  const closeModal = () => {
    formik.resetForm();

    setFormVisible(false);
  };
  return (
    <>
      {formVisible ? (
        <FormElem onSubmit={formik.handleSubmit}>
          {isLoading && <LoaderElem />}
          <ContentContainerElem>
            <Container spacing={4}>
              <TitleContainer>
                <TextElem
                  tid="SESSION.CREATE.TITLE"
                  type="semi-bold"
                  size="main"
                />
                <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
              </TitleContainer>
              {/* <Grid> */}
              <FieldTextElem
                name={FORM_VALUE_ENUM.NAME}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="SESSION.UPDATE.FORM.NAME"
                value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                error={isFieldError(FORM_VALUE_ENUM.NAME)}
              />
              {/* <ToggleContainer>
                  <Toggle
                    tid="SESSION.UPDATE.FORM.AUTO"
                    checked={getFieldValue(FORM_VALUE_ENUM.AUTO)}
                    name={FORM_VALUE_ENUM.AUTO}
                    handleClick={formik.handleChange}
                  />
                </ToggleContainer>
              </Grid> */}
              <DoubleContainerElem>
                <FieldTextElem
                  name={FORM_VALUE_ENUM.CRM_FORM_NAME}
                  onChange={formik.handleChange}
                  title="WEBINAR.UPDATE.INTEGRATION.FORM.CRM_FORM_NAME"
                  value={getFieldValue(FORM_VALUE_ENUM.CRM_FORM_NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.CRM_FORM_NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.CRM_FORM_NAME)}
                />
                <FieldTextElem
                  name={FORM_VALUE_ENUM.CRM_STATUS}
                  onChange={formik.handleChange}
                  title="WEBINAR.UPDATE.INTEGRATION.FORM.CRM_STATUS"
                  value={getFieldValue(FORM_VALUE_ENUM.CRM_STATUS)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.CRM_STATUS)}
                  error={isFieldError(FORM_VALUE_ENUM.CRM_STATUS)}
                />
              </DoubleContainerElem>

              <FieldContainer>
                <FieldTextElem
                  name={FORM_VALUE_ENUM.ONLINE_MIN}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="SESSION.CREATE.FORM.ONLINE_MIN"
                  value={getFieldValue(FORM_VALUE_ENUM.ONLINE_MIN)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.ONLINE_MIN)}
                  error={isFieldError(FORM_VALUE_ENUM.ONLINE_MIN)}
                  type="customNumber"
                />
                <FieldTextElem
                  name={FORM_VALUE_ENUM.ONLINE_MAX}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="SESSION.CREATE.FORM.ONLINE_MAX"
                  value={getFieldValue(FORM_VALUE_ENUM.ONLINE_MAX)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.ONLINE_MAX)}
                  error={isFieldError(FORM_VALUE_ENUM.ONLINE_MAX)}
                  type="customNumber"
                />

                {/* <FieldTextElem
                  name={FORM_VALUE_ENUM.DATE}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="SESSION.CREATE.FORM.DATE"
                  value={getFieldValue(FORM_VALUE_ENUM.DATE)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
                  error={isFieldError(FORM_VALUE_ENUM.DATE)}
                  min={nowTime}
                  type={
                    getFieldValue(FORM_VALUE_ENUM.AUTO)
                      ? 'time'
                      : 'datetime-local'
                  }
                /> */}
                {getFieldValue(FORM_VALUE_ENUM.AUTO) ? (
                  <FieldTimeInputElem
                    name={FORM_VALUE_ENUM.DATE}
                    onChange={formik.handleChange}
                    title="SESSION.CREATE.FORM.DATE"
                    value={getFieldValue(FORM_VALUE_ENUM.DATE)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
                    error={isFieldError(FORM_VALUE_ENUM.DATE)}
                  />
                ) : (
                  <FieldDataTimeInputElem
                    name={FORM_VALUE_ENUM.DATE}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="SESSION.CREATE.FORM.DATE"
                    value={getFieldValue(FORM_VALUE_ENUM.DATE)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
                    error={isFieldError(FORM_VALUE_ENUM.DATE)}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                  />
                )}

                <SelectElem
                  options={timezonearr}
                  dynamic
                  name={FORM_VALUE_ENUM.TIMEZONE}
                  title="SESSION.CREATE.FORM.TIMEZONE"
                  onChange={setFieldValue}
                  value={setValue(timezonearr, FORM_VALUE_ENUM.TIMEZONE)}
                />
              </FieldContainer>
              <FieldContainer>
                <FieldTimeInputElem
                  name={FORM_VALUE_ENUM.PEAK_START_TIME}
                  onChange={formik.handleChange}
                  title="SESSION.CREATE.FORM.PEAK_START_TIME"
                  value={getFieldValue(FORM_VALUE_ENUM.PEAK_START_TIME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.PEAK_START_TIME)}
                  error={isFieldError(FORM_VALUE_ENUM.PEAK_START_TIME)}
                />
                <FieldTimeInputElem
                  name={FORM_VALUE_ENUM.PEAK_END_TIME}
                  onChange={formik.handleChange}
                  title="SESSION.CREATE.FORM.PEAK_END_TIME"
                  value={getFieldValue(FORM_VALUE_ENUM.PEAK_END_TIME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.PEAK_END_TIME)}
                  error={isFieldError(FORM_VALUE_ENUM.PEAK_END_TIME)}
                />
                <ToggleContainer>
                  <Toggle
                    tid="SESSION.UPDATE.FORM.AUTO"
                    checked={getFieldValue(FORM_VALUE_ENUM.AUTO)}
                    name={FORM_VALUE_ENUM.AUTO}
                    handleClick={formik.handleChange}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    tid="SESSION.UPDATE.FORM.FORCE_START"
                    checked={getFieldValue(FORM_VALUE_ENUM.FORCE_START)}
                    name={FORM_VALUE_ENUM.FORCE_START}
                    handleClick={formik.handleChange}
                  />
                </ToggleContainer>
              </FieldContainer>

              <FieldContainer>
                <ButtonElem
                  type="submit"
                  tid="SESSION.CREATE.BUTTON.CREATE"
                  fill="solid"
                  disabled={isSubmitDisabled()}
                  iconRight={addIcon}
                />
                <ButtonElem
                  type="button"
                  onClick={handleImport}
                  tid="SESSION.CREATE.BUTTON.IMPORT"
                  iconRight={
                    theme.type === THEME_ENUM.LIGHT
                      ? importIconLight
                      : importIcon
                  }
                  color="backgroundThird"
                />
              </FieldContainer>

              {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
            </Container>
          </ContentContainerElem>
        </FormElem>
      ) : (
        <AddContainer onClick={() => setFormVisible(true)}>
          <TextElem tid="SESSION.CREATE.ADD" type="semi-bold" size="main" />
          <AddIconLarge />
        </AddContainer>
      )}
    </>
  );
};

const Container = styled(GridElem)`
  @media screen and (width>900px) {
    gap: ${Spacing(5)};
  }
`;

const FieldContainer = styled(GridElem)`
  @media screen and (width>600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (width>900px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddContainer = styled(TitleContainer)`
  padding: ${Spacing(4)} ${Spacing(5)};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  transition: all 0.2s;
  :hover {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  }
`;

const Toggle = styled(ToggleElem)`
  height: 20px !important;
  width: 33px !important;
  --handle-width: 15px;
  --handle-height: 15px;
  --handle-spacing: 1.8px;
`;

const ToggleContainer = styled.div`
  @media screen and (width>900px) {
    margin-top: 26px;
  }
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: 10px;
  padding: 0 16px;

  height: 46px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  div > div {
    height: 19px;
  }
`;
